import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable, map, tap, of, timer, Subscription, mergeMap, catchError, throwError } from "rxjs";

export abstract class PaginatedRepository<TEntity> {

    private _resource = new BehaviorSubject([] as TEntity[]);
    private _loading = new BehaviorSubject<boolean>(false);
    private _loadingTimerSubscription: Subscription | null = null;
    private _error = new BehaviorSubject<boolean>(false);

    private _resourceCount: BehaviorSubject<number> = new BehaviorSubject(0);
    private _pageSize: BehaviorSubject<number> = new BehaviorSubject(10);
    private _pageNum: BehaviorSubject<number> = new BehaviorSubject(1);
    private _totalPages: BehaviorSubject<number> = new BehaviorSubject(0);

    protected abstract _baseLink: string;
    protected _baseLinkTemplate: string = "";
    private _firstPageLink: string | null = null;
    private _prevPageLink: string | null = null;
    private _nextPageLink: string | null = null;
    private _lastPageLink: string | null = null;

    protected _currentParams: HttpParams = new HttpParams();

    public resource$ = this._resource.asObservable();
    public loading$ = this._loading.asObservable();
    public resourceCount$ = this._resourceCount.asObservable();
    public pageSize$ = this._pageSize.asObservable();
    public pageNum$ = this._pageNum.asObservable().pipe(map(pageNum => pageNum - 1 < 0 ? 0 : pageNum - 1));
    public totalPages$ = this._totalPages.asObservable();
    public error$ = this._error.asObservable();

    constructor(
        protected http: HttpClient,
        protected messageService: ToastrService) { }

    public page(pagingEvent: PageEvent) {
        this.startLoading();

        this.setParam('pageNum', pagingEvent.pageIndex + 1 > this._totalPages.value ? 1 : pagingEvent.pageIndex + 1);
        this.setParam('pageSize', pagingEvent.pageSize);

        let request = this.http.get<TEntity[]>(this._baseLink, { observe: 'response', params: this._currentParams});
        this.pipePagedRequest(request).subscribe(entities => this.handleEntities(entities));
    }

    //For base links that contain additiona input parameters. Ex /Corporations/{corporationID}/ConfigurationDetails
    public populateBaseLink(routeParams: string[])
    {
        var tempURL = this._baseLinkTemplate;
        for (let i = 0; i < routeParams.length; i++)
        {
            tempURL = tempURL.replace(`[${i}]`, routeParams[i]);
        }
        this._baseLink = tempURL;
    }

    public getFirstPage(params?: HttpParams) {
        return this.getFirstPageSubscribable(params).subscribe();
    }

    public getFirstPageSubscribable(params?: HttpParams) {
        this.startLoading();

        let request;
        if(params) {
            this._currentParams = params;
            request = this.http.get<TEntity[]>(this._baseLink, { observe: 'response', params: params });
        } else if(this._currentParams) {
            request = this.http.get<TEntity[]>(this._baseLink, { observe: 'response', params: this._currentParams });
        } else {
            request = this.http.get<TEntity[]>(this._baseLink, { observe: 'response' });
        }

        return this.pipePagedRequest(request).pipe(tap(entities => this.handleEntities(entities)));
    }

    public filter(params: HttpParams) {
        this.filterSubscribable(params).subscribe();
    }
 
    public filterSubscribable(params: HttpParams) {
        this.startLoading();

        this._currentParams = params;
        this.setParam('pageSize', this._pageSize.value);
        this.setParam('pageNum', this._pageNum.value);

        var request = this.http.get<TEntity[]>(this._baseLink, { observe: 'response', params: this._currentParams });
        return this.pipePagedRequest(request).pipe(tap(entities => this.handleEntities(entities)));
    }

    public sort(sortingEvent: Sort) {
        this.startLoading();

        if(sortingEvent.direction) {
            this.setParam('sortColumn', sortingEvent.active);
            this.setParam('sortDirection', sortingEvent.direction);
        } else {
            this.clearParam('sortColumn');
            this.clearParam('sortDirection');
        }

        let request = this.http.get<TEntity[]>(this._baseLink, { observe: 'response', params: this._currentParams });
        this.pipePagedRequest(request).subscribe(entities => this.handleEntities(entities));
    }

    public refresh() {
        this.refreshSubscribable().subscribe();
    }

    public refreshSubscribable(): Observable<TEntity[]> {
        let request = this.http.get<TEntity[]>(this._baseLink, { observe: 'response', params: this._currentParams });
        return this.pipePagedRequest(request).pipe(tap(entities => this.handleEntities(entities)));
    }

    public clearFilters() {
        this._currentParams = new HttpParams();
    }

    private startLoading() {
        this._error.next(false);
        this._loadingTimerSubscription = timer(500).subscribe(() => this._loading.next(true));
    }

    private finishLoading() {
        this._loadingTimerSubscription?.unsubscribe();
        this._loading.next(false);
    }

    private handleEntities(entities: TEntity[]) {
        this._resource.next(entities);
        this.finishLoading();
    }

    private pipePagedRequest(request: Observable<HttpResponse<TEntity[]>> | null): Observable<TEntity[]> {
        if (!request) {
            return of([]);
        }

        return request.pipe(
            catchError(error => {
                this._error.next(true);
                this.finishLoading();
                return throwError(() => new Error(error.message));
            }),
            tap(response => {
                this.parsePaginationHeaders(response.headers)
            }),
            mergeMap(response => {
                if(this._pageNum.value > this._totalPages.value && this._totalPages.value > 0) {
                    this.setParam('pageNum', this._totalPages.value);
                    return this.http.get<TEntity[]>(this._baseLink, { observe: 'response', params: this._currentParams })
                        .pipe(tap(response => this.parsePaginationHeaders(response.headers)));
                } else {
                    return of(response);
                }
            }),
            map(response => response.body ? response.body : [])
        )
    }

    private setParam(key: string, value: string | number | boolean) {
        this._currentParams = this._currentParams.set(key, value);
    }

    private clearParam(key: string) {
        this._currentParams = this._currentParams.delete(key);
    }

    private parsePaginationHeaders(headers: HttpHeaders) {
        let resourceCount = this.parseResourceCountHeader(headers);
        if(resourceCount) {
            this._resourceCount.next(resourceCount);
        }

        let pageSize = headers.get('x-pagination-pagesize');
        if (pageSize) {
            const num = +pageSize;
            this._pageSize.next(num <= 0 ? 1 : num);
        }

        let pageNum = headers.get('x-pagination-pagenum');
        if(pageNum) {
            const num = +pageNum;
            this._pageNum.next(num <= 0 ? 1 : num);
        }

        let totalPages = headers.get('x-pagination-totalpages');
        if(totalPages) {
            const num = +totalPages;
            this._totalPages.next(num <= 0 ? 1 : num);
        }

        this._firstPageLink = headers.get('x-pagination-firstpage') ?? null;
        this._prevPageLink = headers.get('x-pagination-prevpage') ?? null;
        this._nextPageLink = headers.get('x-pagination-nextpage') ?? null;
        this._lastPageLink = headers.get('x-pagination-lastpage') ?? null;
    }

    protected parseResourceCountHeader(headers: HttpHeaders): number {
        let resourceCount = headers.get('x-pagination-resourcecount');

        if (resourceCount) {
            const num = +resourceCount;
            return num < 0 ? 1 : num;
        }

        return NaN;
    }
}
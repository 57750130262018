import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, map } from 'rxjs';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { OrderAudit } from '../../interfaces/order-audits/order-audit';
import { OrderAuditForCreation } from '../../interfaces/order-audits/order-audit-for-creation';
import { OrderAuditForUpdate } from '../../interfaces/order-audits/order-audit-for-update';

@Injectable({
    providedIn: 'root'
})
export class OrderAuditsRepositoryService extends PaginatedRepository<OrderAudit> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(
            http,
            messageService);

        this._baseLink = `${config.rootUrl}/OrderAudits`;
    }

    private generateHeaders = () => {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    }

    public getOrderAudit = (id: string): Observable<OrderAudit> => {
        return this.http.get<OrderAudit>(`${this._baseLink}/${id}`, this.generateHeaders());
    }

    public createOrderAudit = (orderAudit: OrderAuditForCreation): Observable<OrderAudit> => {
        return this.http.post<OrderAudit>(`${this._baseLink}`, orderAudit, this.generateHeaders());
    }

    public updateOrderAudit = (id: string, orderAudit: OrderAuditForUpdate): Observable<OrderAudit> => {
        return this.http.put<OrderAudit>(`${this._baseLink}/${id}`, orderAudit, this.generateHeaders());
    }

    public deleteOrderAudit = (id: string) => {
        return this.http.delete(`${this._baseLink}/${id}`, this.generateHeaders());
    }

    public getPendingAuditCount = (): Observable<number> => {
        var params = new HttpParams()
            .set('isAudited', false)
            .set('pageSize', 100);

        return this.http.get<OrderAudit[]>(this._baseLink, { observe: 'response', params: params }).pipe(map(response => {
            return this.parseResourceCountHeader(response.headers)
        }));
    }

    public getByOrderID = (orderID: string) => {
        var params = new HttpParams()
            .set('orderID', orderID)
            .set('pageSize', 100);

        return this.http.get<OrderAudit[]>(this._baseLink, { params: params });
    }
}
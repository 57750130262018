import { ePartyType } from "reg-hub-common";

export class Constants {
    public static apiRoot = "https://bapi.reghub.dev/api/v1";
    public static clientRoot = "https://admin.reghub.dev";
    public static idpAuthority = "https://auth.reghub.dev";
    public static clientId = "web.client";

    // url parameters
    public static corporationID = "corporationID";
    public static userGroupID = "userGroupID";
    public static userID = "userID";

    public static statusComplete = "Complete"

    public static businessDebtor = 1;
	public static individualDebtor = 2;
	public static businessSecuredParty = 3;
	public static individualSecuredParty = 4;
	public static businessRegisteringAgent = 5
	public static individualRegisteringAgent = 6;
    public static businessDealer = 7;
    public static individualDealer = 8;

    public static isDebtor(partyType: number | string) {
        return partyType == Constants.businessDebtor || partyType == Constants.individualDebtor
            || partyType == ePartyType.businessDebtor || partyType == ePartyType.individualDebtor;
    }

    public static isSecuredParty(partyType: number | string) {
        return partyType == Constants.businessSecuredParty || partyType == Constants.individualSecuredParty
            || partyType == ePartyType.businessSecuredParty || partyType == ePartyType.individualSecuredParty;
    }

    public static isDealer(partyType: number | string) {
        return partyType == Constants.businessDealer || partyType == Constants.individualDealer
            || partyType == ePartyType.businessDealer || partyType == ePartyType.individualDealer;
    }

    public static isRegisteringAgent(partyType: number | string) {
        return partyType == Constants.businessRegisteringAgent || partyType == Constants.individualRegisteringAgent
            || partyType == ePartyType.businessRegisteringAgent || partyType == ePartyType.individualRegisteringAgent;
    }

    public static ordersHubNegotiateUrl: string = "/Hubs/Orders/Negotiate";
    public static orderMessagesHubNegotiateUrl: string = "/Hubs/OrderMessages/Negotiate";
}
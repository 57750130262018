<mat-dialog-content>

  <div class="table-container">
  <table mat-table matSort [dataSource]="orderMessages" *ngIf="orderMessages.length > 0" multiTemplateDataRows class="mat-elevation-z2">
    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef> Message </th>
      <td mat-cell *matCellDef="let message" class="preserve-line-breaks"> {{ message.message }} </td>
    </ng-container>

    <ng-container matColumnDef="added">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="added"> Added
      </th>
      <td mat-cell *matCellDef="let message">
        {{ message.added | localizedDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="customerRead">
      <th mat-header-cell *matHeaderCellDef>{{isCustomer ? "Read" : "Read By Customer"}}</th>
      <td mat-cell *matCellDef="let message">
        <mat-checkbox class="padding-checkbox" [(ngModel)]="message.isReadByCustomer" [disabled]="!isCustomer" (change)="updateOrderMessage(message)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="adminRead">
      <th mat-header-cell *matHeaderCellDef>Read by Admin</th>
      <td mat-cell *matCellDef="let message">
        <mat-checkbox class="padding-checkbox" [(ngModel)]="message.isReadByAdmin" (change)="updateOrderMessage(message)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="customerVisible">
      <th mat-header-cell *matHeaderCellDef>Customer Visible</th>
      <td mat-cell *matCellDef="let message">
        <mat-checkbox class="padding-checkbox" [(ngModel)]="message.isCustomerVisible" (change)="updateOrderMessage(message)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete" *ngIf="!isCustomer">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let message">
        <button (click)="deleteMessage(message)" mat-icon-button matTooltip="Delete" matTooltipClass="reghub-tooltip"
          aria-label="Button that removes the party code." color="warn">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="this.isCustomer ? this.customerDisplayedColumns : this.adminisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: (this.isCustomer ? this.customerDisplayedColumns : this.adminisplayedColumns);">
    </tr>
  </table>
  <lib-placeholder iconName="check_circle" iconClass="icon-success" message="No order messages found"
                [condition]="orderMessages.length === 0"></lib-placeholder>
  <div>
    <div class="flex-container">
      <h2>New Message</h2>
    </div>
    <div class="flex-container" *ngIf="!isCustomer">
      <div class="flex-item">
        <mat-checkbox [(ngModel)]="newMessage.isCustomerVisible">
          Customer Visible
        </mat-checkbox>
      </div>
    </div>
    <div class="flex-container">
      <mat-form-field class="form-field flex-item">
        <mat-label>Message</mat-label>
        <textarea matInput [(ngModel)]="newMessage.message" placeholder="Message"></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="true" class="padding">Close</button>
  <span class="spacer"></span>
  <button mat-raised-button
    (click)="saveMessage()" color="accent" class="padding"
    aria-label="Save corporation button" [class.spinner]="this.isSaving" [disabled]="this.isSaving"
    [mat-dialog-close]="true">
    Save New
  </button>
</mat-dialog-actions>
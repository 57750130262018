import { Inject, Injectable } from '@angular/core';
import { OnGroupDataMessageArgs, WebPubSubClient } from '@azure/web-pubsub-client';
import { SystemEvent } from 'projects/reg-hub-common/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { HubService } from '../hubs-service';
import { HttpClient } from '@angular/common/http';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../../reg-hub-common-config';

@Injectable({
  providedIn: 'root'
})
export class SystemEventsHubService extends HubService {
  private client: WebPubSubClient | undefined;

  private eventsSubject = new BehaviorSubject<SystemEvent[]>([]);
  public events$ = this.eventsSubject.asObservable();

  private isConnected: boolean = false;

  constructor(
    private http: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) { super(); }

  public connect() {
    if (!this.isConnected) {
      this.http.get<any>(`${this.config.rootUrl}/Hubs/SystemEvents/Negotiate`)
        .subscribe({
          next: (response: any) => this.completeConnection(response.url),
          error: (err: any) => console.error("Error negotiating hub connection.", err)
        });
    }
  }

  public disconnect() {
    if (this.isConnected) {
      this.client?.stop();
    }
  }

  private completeConnection(url: string): void {
    this.client = new WebPubSubClient({
      getClientAccessUrl: async () => url
    });

    this.client.on("connected", () => {
      this.isConnected = true;
    });

    this.client.on("group-message", (messageEvent) => {
      this.handleGroupMessageReceived(messageEvent);
    });

    this.client?.joinGroup("SystemEvents");

    this.client.on("disconnected", () => {
      this.isConnected = false;
    });

    this.client.start();
  }

  handleGroupMessageReceived(messageEvent: OnGroupDataMessageArgs) {
    if (messageEvent.message.dataType === "text") {
      const rawEvents = JSON.parse(messageEvent.message.data as string, this.toCamelCase);
      const events = rawEvents as SystemEvent[];
      this.eventsSubject.next(events);
    }
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OnGroupDataMessageArgs, WebPubSubClient } from '@azure/web-pubsub-client';
import { Observable, ReplaySubject, BehaviorSubject, from } from 'rxjs';
import { Constants } from 'projects/reg-hub-admin/src/constants';
import { EnvironmentUrlService } from '../../environment-url/environment-url.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersAuditsHubService implements OnDestroy {
  private client: WebPubSubClient | undefined;
  private messagesSubject = new ReplaySubject<any>(1);  // stores last message, can be Subject if you prefer
  public messages$: Observable<any> = this.messagesSubject.asObservable();

  private pendingAuditCount = 'PendingAuditCount';

  private joinedGroups = new Set<string>();
  private isConnected = false;

  // BehaviorSubjects to store orders
  private pendingAuditCountSource = new BehaviorSubject<number>(0);
  pendingAuditCount$ = this.pendingAuditCountSource.asObservable();

  constructor(private http: HttpClient,
    private environmentUrl: EnvironmentUrlService
  ) {}

  connect(): void {
    if (!this.isConnected) {
      this.http.get<any>(`${this.environmentUrl.urlAddress}${Constants.ordersHubNegotiateUrl}`)
        .subscribe({
          next: response => this.completeConnection(response.url),
          error: err => console.error("Error negotiating URL:", err)
        });
    }
  }

  private completeConnection(url: string): void {
    this.client = new WebPubSubClient({
      getClientAccessUrl: async () => url
    });

    // Set up event handlers
    this.client.on("connected", (e) => {
      console.log("Connected to Orders hub:", e.connectionId);
      this.isConnected = true;
    });

    // handle messages to a group when received
    this.client.on("group-message", (messageEvent) => {
      this.handleGroupMessageReceived(messageEvent);
    });

    // handle disconnection from the hub
    this.client.on("disconnected", () => {
      console.warn("Disconnected from Web PubSub");
      this.isConnected = false;
    });

    // Start the connection
    from(this.client.start()).subscribe({
      next: () => {
        console.log('Client started');
        this.joinPendingAuditsGroup();
      },
      error: err => console.error('Error starting client', err)
    });
  }

  private joinGroupIfNotJoined(groupName: string) {
    if (!this.joinedGroups.has(groupName)) {
      this.client?.joinGroup(groupName)
        .then(() => {
          this.joinedGroups.add(groupName);
        })
    }
  }

  private leaveGroupIfJoined(groupName: string) {
    if (this.joinedGroups.has(groupName)) {
      this.client?.leaveGroup(groupName)
        .then(() => {
          this.joinedGroups.delete(groupName);
        })
    }
  }

  joinPendingAuditsGroup() {
    this.joinGroupIfNotJoined(this.pendingAuditCount);
  }

  leavePendingAuditsGroup() {
    this.leaveGroupIfJoined(this.pendingAuditCount);
  }

  handleGroupMessageReceived(messageEvent: OnGroupDataMessageArgs) {
    if (messageEvent.message.dataType === "text") {
      const group: string = messageEvent.message.group;
      const count = messageEvent.message.data as number;

      switch (group) {
        case this.pendingAuditCount:
          this.updatePendingAuditCount(count);
          break;
        default:
          // This shouldnt happen, we only subscribed to the three groups here? Maybe for Orders?
          console.warn(`Received a message from an unhandled group: ${group}`, messageEvent.message.data);
          break;
      }

      this.messagesSubject.next(count);
    }
  }

  setInitialAuditPendingCount(count: number) {
    this.pendingAuditCountSource.next(count);
  }

  private updatePendingAuditCount(count: number) {
    this.pendingAuditCountSource.next(count);
  }

  ngOnDestroy() {
    // Stop the connection if the service is destroyed (e.g., in certain testing scenarios)
    if (this.client) {
      this.client.stop();
    }
  }

  toCamelCase(key: any, value: any) {
    if (value && typeof value === 'object'){
      for (var k in value) {
        // Special case: If the key is exactly 'ID', rename it to 'id'
        if (k === 'ID') {
          value['id'] = value[k];
          delete value[k];
        }
        // Otherwise, just lowercase the first letter
        else if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }
}
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdminAuthService } from '../../../services/auth/admin-auth.service';
import { Constants } from '../../../../constants';
import { EnvironmentUrlService } from '../../../services/environment-url/environment-url.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AdminAuthService,
    private environmentUrl: EnvironmentUrlService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.startsWith(this.environmentUrl.urlAddress) || 
      req.url.startsWith(this.environmentUrl.batchUrlAddress) ||
      req.url.startsWith(this.environmentUrl.consortiumRootURL)){
      var token = this.authService.getAccessToken()
      const authRequest = req.clone({ headers: req.headers.append('Authorization', `Bearer ${token}`) });
      return next.handle(authRequest);
    }
    else {
      return next.handle(req);
    }
  }
}
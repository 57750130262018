import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagesStateService {
  private unreadMessagesCountSubject = new BehaviorSubject<number>(0);

  readonly unreadMessagesCount$ = this.unreadMessagesCountSubject.asObservable();

  setUnreadMessageCount(count: number) {
    this.unreadMessagesCountSubject.next(count);
  }
}
<div class="padding">
    <mat-card>
        <mat-card-content>
            <!-- Main orders table -->
            <div class="table-container" *ngIf="data.length > 0">
                <h3>Failed Orders</h3>

                <!-- Orders table -->
                <table mat-table [dataSource]="data" multiTemplateDataRows *ngIf="data.length > 0">

                    <ng-container matColumnDef="referenceNumber">
                        <th mat-header-cell *matHeaderCellDef id="referenceNumber"> Ref No. </th>
                        <td mat-cell *matCellDef="let order"> {{order.referenceNumber}} </td>
                        <td mat-footer-cell *matFooterCellDef class="bold"> Total Failed Orders: </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef id="status"> Status </th>
                        <td mat-cell *matCellDef="let order"> {{order.orderStatusTypeID}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>

                    <ng-container matColumnDef="jurisdiction">
                        <th mat-header-cell *matHeaderCellDef id="jurisdiction"> Juris. </th>
                        <td mat-cell *matCellDef="let order"> {{order.displayJurisdiction}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>

                    <ng-container matColumnDef="orderTypeID">
                        <th mat-header-cell *matHeaderCellDef id="orderTypeID"> Type </th>
                        <td mat-cell *matCellDef="let order"> {{order.orderType.name}} </td>
                        <td mat-footer-cell *matFooterCellDef class="bold"> {{ this.total }} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable-table-row"
                        [routerLink]="['/orders', row.id]" @tableAnimation></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </table>
                <!-- End of orders table-->

                <div class="flex-container-right-align">
                    <button mat-raised-button color="accent" class="margin-sides-5"
                        aria-label="View all failed orders button" type="submit" (click)="onViewAll()">
                        View All
                    </button>
                </div>
            </div>
            <!-- End of main orders table-->

            <lib-placeholder iconName="cancel" iconClass="icon-error" message="Error loading failed orders"
                [condition]="(this.repo.error$ | async) ?? false && !loading"></lib-placeholder>
            <lib-placeholder iconName="check_circle" iconClass="icon-success" message="No failed orders found"
                [condition]="!(this.repo.error$ | async) && !loading && data.length === 0"></lib-placeholder>

        </mat-card-content>
    </mat-card>
</div>
<div class="margin-bottom-5">
    <div class="flex-container" *ngIf="uiConfiguration.showOriginalQCFormTypeID">
        <span class="flex-item-3">Original Form Type:</span>
        <span class="flex-item-4" data-testid="original-form-type-text">{{ referenceRegistrationDetails?.originalQCFormTypeID?.toString() }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showOriginalOrderTypeID">
        <span class="flex-item-3">Original Registration Type:</span>
        <span class="flex-item-4" data-testid="original-registration-type-text">{{ formatOriginalOrderType(referenceRegistrationDetails?.originalOrderTypeID) }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showOriginalSigningDate">
        <span class="flex-item-3">Original Signing Date:</span>
        <span class="flex-item-4" data-testid="original-signing-date-text">{{ referenceRegistrationDetails?.originalSigningDate | localizedDate: 'yyyy-MM-dd' }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showFileNumber && order.lien?.fileNumber">
        <span class="flex-item-3">File Number:</span>
        <span class="flex-item-4" data-testid="file-number-text">{{ order.lien?.fileNumber }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showRegistrationNumber">
        <span class="flex-item-3">Registration Number:</span>
        <span class="flex-item-4" data-testid="registration-number-text">{{ referenceRegistrationDetails?.registrationNumber ?? referenceRegistrationDetails?.originalRegistrationNumber }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showOriginalRegistrationNumber">
        <span class="flex-item-3">Original Registration Number:</span>
        <span class="flex-item-4" data-testid="original-registration-number-text">{{ referenceRegistrationDetails?.originalRegistrationNumber }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showRIN">
        <span class="flex-item-3">RIN:</span>
        <span class="flex-item-4" data-testid="rin-text">{{ referenceRegistrationDetails?.rin ?? "NO RIN" }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showRegistrationDate">
        <span class="flex-item-3">Registration Date:</span>
        <span class="flex-item-4" data-testid="registration-date-text">{{ referenceRegistrationDetails?.originalRegistrationDate | localizedDate: 'yyyy-MM-dd' }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showExpiryDate">
        <span class="flex-item-3">Current Expiry Date:</span>
        <span class="flex-item-4" data-testid="current-expiry-date-text">{{ (referenceRegistrationDetails?.expiryDate == null) ? "NO EXPIRY DATE" : referenceRegistrationDetails?.expiryDate | localizedDate: 'yyyy-MM-dd' }}</span>
    </div>
    <div class="flex-container" *ngIf="showCurrentExpiryDate()">
        <span class="flex-item-3">Original Expiry Date:</span>
        <span class="flex-item-4" data-testid="current-expiry-date-text">{{ (referenceRegistrationDetails?.originalExpiryDate == null) ? "NO EXPIRY DATE" : referenceRegistrationDetails?.originalExpiryDate | localizedDate: 'yyyy-MM-dd' }}</span>
    </div>
</div>
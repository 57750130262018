import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentUrlService {

  urlAddress: string = environment.urlAddress;
  batchUrlAddress: string = environment.batchUrlAddress;
  consortiumRootURL: string = environment.consortiumUrlAddress 
  
  constructor() { }
}
<div class="flex-container">
    <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Search By</mat-label>
        <mat-select [(ngModel)]="this.typeOfSearch" placeholder="Select an option">
            <mat-option *ngFor="let option of searchOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="flex-item-5 flex-container">
        <mat-label>Search</mat-label>
        <input matInput name="search" (keyup.enter)="performSearch()" [(ngModel)]="this.searchCriteria" placeholder="Search">
        <button
        matSuffix mat-raised-button
            (click)="performSearch()"
            color="accent"
            class="margin-sides-15"
            aria-label="Search button"
            [disabled]="!this.searchCriteria">
            Search
        </button>
    </mat-form-field>
</div>
<div class="flex-container">
    <!-- left column -->
    <div class="flex-container-column">
        <div class="flex-container">
            <span class="flex-item"><b>City:</b></span>
            <span class="flex-item-2">{{ result.address?.city }}</span>
        </div>

        <div class="flex-container">
            <span class="flex-item"><b>Type:</b></span>
            <span class="flex-item-2">{{ result.companyType }}</span>
        </div>

        <div class="flex-container">
            <span class="flex-item"><b>Description:</b></span>
            <span class="flex-item-2">{{ result.description }}</span>
        </div>

        <div class="flex-container">
            <span class="flex-item"><b>Jurisdiction Status:</b></span>
            <span class="flex-item-2">{{ result.jurisdictionStatus }}</span>
        </div>
    </div>

    <!-- right column -->
    <div class="flex-container-column">
        <div class="flex-container">
            <span class="flex-item"><b>Creation Date:</b></span>
            <span class="flex-item-2">{{ result.creationDate | localizedDate: 'yyyy-MM-dd' }}</span>
        </div>

        <div class="flex-container">
            <span class="flex-item"><b>Status Date:</b></span>
            <span class="flex-item-2">{{ result.statusDate | localizedDate: 'yyyy-MM-dd' }}</span>
        </div>
    </div>
</div>

<div *ngIf="result.businessReports && result.businessReports.length > 0" class="flex-container">
    <span class="flex-item"><b>View Previous Report:</b></span>
    <mat-form-field appearance="outline" class="flex-item-2">
        <mat-label>Previous Reports</mat-label>
        <mat-select (selectionChange)="onReportSelected($event.value)">
            <mat-option *ngFor="let report of getReports()" [value]="report.businessReportOrderID">
                {{ report.added | localizedDate: 'yyyy-MM-dd' }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <span class="flex-item">&nbsp;</span>
    <span class="flex-item-2">&nbsp;</span>
</div>
<mat-sidenav-container>
    <mat-sidenav [opened]="isSidenavOpen" mode="side">
        <div class="mat-sidenav-wrapper">
            <div class="mat-nav-wrapper">
                <mat-nav-list mat-button>
                    <a mat-list-item *ngFor="let item of navItems" [routerLink]="item.link"
                       routerLinkActive="activeBackground" 
                       [attr.data-testid]="'side-menu-option-' + item.label.toLowerCase()">
                      <span matListItemIcon>
                        <mat-icon>{{ item.icon }}</mat-icon>
                      </span>
                      <span matListItemTitle routerLinkActive="activeText"
                            matBadge="{{ item.label === 'Audit Queue' ? pendingAuditCount : 
                                        (item.label === 'Messages' ? unreadMessageCount : '') }}"
                            matBadgeSize="small" matBadgeOverlap="false"
                            [matBadgeHidden]="(item.label === 'Audit Queue' && pendingAuditCount < 1) ||
                                             (item.label === 'Messages' && unreadMessageCount < 1)">
                        {{ item.label }}
                      </span>
                    </a>
                  </mat-nav-list>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <div class="spacer-small"></div>
        <div class="padding-sides-15">
            <lib-search-bar [shouldShowExternalOrderNumber]="true" (search)="onSearch($event)" data-testid="side-menu-search-bar"></lib-search-bar>
        </div>
        <div toastContainer aria-live="polite"></div>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
export enum eOrderType {
    notSet = "NotSet",
    lien = "Lien",
    renewal = "Renewal",
    discharge = "Discharge",
    amendment = "Amendment",
    debtorSearch = "DebtorSearch",
    assetSearch = "AssetSearch",
    repairLien = "RepairLien",
    basicRenewal = "BasicRenewal",
    basicDischarge = "BasicDischarge",
    certifiedDebtorSearch = "CertifiedDebtorSearch",
    businessSearch = "BusinessSearch",
	businessReport = "BusinessReport"
}

export enum eOrderTypeGroup {
    PPSARegistration = "PPSARegistration",
    PPSASearch = "PPSASearch",
    BasicPPSARegistration = "BasicPPSARegistration",
    PPSAChangeManagement = "PPSAChangeManagement",
    BusinessSearch = "BusinessSearch"
}

export enum ePartyType {
    businessDebtor = "BusinessDebtor",
    individualDebtor = "IndividualDebtor",
    businessSecuredParty = "BusinessSecuredParty",
    individualSecuredParty = "IndividualSecuredParty",
    businessRegisteringAgent = "BusinessRegisteringAgent",
    individualRegisteringAgent = "IndividualRegisteringAgent",
    businessDealer = "BusinessDealer",
    individualDealer = "IndividualDealer"
}

export enum eAssetType {
    None = 0,
    MotorVehicle = 1,
    Trailer = 2,
    Boat = 3,
    MobileHome = 4,
    OutboardMotor = 5,
    AircraftRegisteredInCanada = 6,
    AircraftNonRegisteredInCanada = 7,
    ManufacturedHome = 8,
    FarmVehicle = 9,
    Motorcycle = 10,
    Bus = 11,
    MiniBus = 12,
    Taxi = 13,
    AllTerrainVehicle = 14,
    SnowmobilePost1988 = 15,
    PassengerVehicle = 16,
    EmergencyVehicle = 17,
    CommercialVehicle = 18,
    Other = 19,
    AircraftCanada = 20,
    AircraftForeign = 21,
    AircraftAirframeRegisteredInCanada = 22,
    AircraftAirframeNotRegisteredInCanada = 23,
    Aircraft = 24,
    AircraftDOT = 25,
    AircraftSerial = 26,
    TrailerOrSemiTrailer = 27,
    MotorHome = 28,
}

export const assetTypeLookup: { [key: string]: eAssetType } = {
    MotorVehicle: eAssetType.MotorVehicle,
    Trailer: eAssetType.Trailer,
    Boat: eAssetType.Boat,
    MobileHome: eAssetType.MobileHome,
    OutboardMotor: eAssetType.OutboardMotor,
    AircraftRegisteredInCanada: eAssetType.AircraftRegisteredInCanada,
    AircraftNonRegisteredInCanada: eAssetType.AircraftNonRegisteredInCanada,
    ManufacturedHome: eAssetType.ManufacturedHome,
    FarmVehicle: eAssetType.FarmVehicle,
    Motorcycle: eAssetType.Motorcycle,
    Bus: eAssetType.Bus,
    MiniBus: eAssetType.MiniBus,
    Taxi: eAssetType.Taxi,
    AllTerrainVehicle: eAssetType.AllTerrainVehicle,
    SnowmobilePost1988: eAssetType.SnowmobilePost1988,
    PassengerVehicle: eAssetType.PassengerVehicle,
    EmergencyVehicle: eAssetType.EmergencyVehicle,
    CommercialVehicle: eAssetType.CommercialVehicle,
    Other: eAssetType.Other,
    AircraftCanada: eAssetType.AircraftCanada,
    AircraftForeign: eAssetType.AircraftForeign,
    AircraftAirframeRegisteredInCanada: eAssetType.AircraftAirframeRegisteredInCanada,
    AircraftAirframeNotRegisteredInCanada: eAssetType.AircraftAirframeNotRegisteredInCanada,
    Aircraft: eAssetType.Aircraft,
    AircraftDOT: eAssetType.AircraftDOT,
    AircraftSerial: eAssetType.AircraftSerial,
    TrailerOrSemiTrailer: eAssetType.TrailerOrSemiTrailer,
    MotorHome: eAssetType.MotorHome
};

export enum eOrderStatusType {
    draft = "Draft",
    pending = "Pending",
    complete = "Complete",
    invalid = "Invalid",
    requestedAtRegistry = "RequestedAtRegistry",
    failedAutomation = "FailedAutomation",
    manualFulfillment = "ManualFulfillment",
    outOfRegistryHours = "OutOfRegistryHours",
    awaitingHighVolumeResults = "AwaitingHighVolumeResults",
    historicalDraft = "HistoricalDraft",
    historical = "Historical",
    registryOutage = "RegistryOutage",
    exception = "Exception",
    errorCorrection = "ErrorCorrection",
    documentProcessing = "DocumentProcessing",
    cancelled = "Cancelled",
    documentReProcessing = "DocumentReProcessing",
    staged = "Staged"
}

export const orderStatusTypeLookup: { [key: number]: string } = {
    1: "Draft",
    2: "Pending",
    3: "Complete",
    4: "Invalid",
    5: "RequestedAtRegistry",
    6: "FailedAutomation",
    7: "ManualFulfillment",
    8: "OutOfRegistryHours",
    9: "AwaitingHighVolumeResults",
    10: "HistoricalDraft",
    11: "Historical",
    12: "RegistryOutage",
    13: "Exception",
    14: "ErrorCorrection",
    15: "DocumentProcessing",
    16: "Cancelled",
    17: "DocumentReProcessing",
    18: "Staged"
};

export enum eGeneration {
    Jr = "Jr.",
    Sr = "Sr.",
    Esquire = "Esquire",
    First = "First",
    Second = "Second",
    Third = "Third",
    Fourth = "Fourth",
    Fifth = "Fifth",
    Sixth = "Sixth",
    Elder = "Elder",
    Younger = "Younger"
}

export enum eMatSelectAppearance {
    legacy = "legacy",
    standard = "standard",
    fill = "fill",
    outline = "outline"
}

export enum eLogSeverity {
    Debug = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4
}

export enum eUserType {
    Api = 1,
    Portal = 2,
}

export enum eQCFormType {
    none = 0,
    RHa = "RHa",
    RDd = "RDd",
    RDe = "RDe",
    RG34 = "RG34",
    RG41 = "RG41",
    RV = "RV",
    RRa = "RRa",
    RHf = "RHf",
    RDf = "RDf",
    RG80 = "RG80",
    RE = "RE"
}

export enum eSystemEventType {
    ScheduledMaintenance = 1,
    RegistryOutage = 2
}

export enum eOrderChangeOperation {
    Replace = "Replace",
    Add = "Add",
    Remove = "Remove"
}

export enum eDatabaseType {
    Primary = 1,
    Customer = 2
}

export enum eAmendmentType {
    change = "Change",
    correction = "Correction"
}

export enum eDischargeType {
    totalDischarge = "TotalDischarge",
    agreementToDischarge = "AgreementToDischarge"
}

export enum eEnvelopeStatusType {
    error = "Error",
    open = "Open",
    sealed = "Sealed",
    imported = "Imported",
    resultsPending = "ResultsPending",
    complete = "Complete"
}

export enum eBatchState {
    preProcessing = "PreProcessing",
    processingFailed = "ProcessingFailed",
    processing = "Processing",
    stopped = "Stopped",
    completed = "Complete",
    review = "Review",
    audit = "Audit"
}

export enum eBatchType {
    historicalLien = "HistoricalLien",
    lien = "Lien",
    renewal = "Renewal",
    discharge = "Discharge",
    assetSearch = "AssetSearch",
    debtorSearch = "DebtorSearch"
}

export enum eResultState {
    noResult = "NoResult",
    rawDocument = "RawDocument",
    parsedDocument = "ParsedDocument",
    resultsOverflow = "ResultsOverflow"
}

export enum eResultStateID {
    noResult = 1,
    rawDocument = 2,
    parsedDocument = 3,
    resultsOverflow = 4
}

export enum eRegistryForm {
    ontarioChangesCorrectionsOmissionsPPSA = "OntarioChangesCorrectionsOmissionsPPSA",
    ontarioRenewalPPSA = "OntarioRenewalPPSA",
    ontarioFullDischargePPSA = "OntarioFullDischargePPSA",
    ontarioAssignmentBySecuredPartyPPSA = "OntarioAssignmentBySecuredPartyPPSA",
    ontarioTransferByDebtorPPSA = "OntarioTransferByDebtorPPSA",
    ontarioPartialDischargePPSA = "OntarioPartialDischargePPSA",
    ontarioAssignmentOfPartOfTheCollateralPPSA = "OntarioAssignmentOfPartOfTheCollateralPPSA",
    ontarioTransferOfPartOfTheCollateralPPSA = "OntarioTransferOfPartOfTheCollateralPPSA",
    ontarioSubordinationPostponementPPSA = "OntarioSubordinationPostponementPPSA",
    ontarioBlankFormPPSA = "OntarioBlankFormPPSA",
    quebecRHa = "QuebecRHa", //Lien
    quebecRDd = "QuebecRDd", //Lien
    quebecRDe = "QuebecRDe", //Lien
    quebecRG34 = "QuebecRG34", //Amendment (Change)
    quebecRG41 = "QuebecRG41", //Renewal
    quebecRV = "QuebecRV", //Discharge
    quebecRRa = "QuebecRRa", //Amendment (Correction)
    quebecRHf = "QuebecRHf", //Renewal
    quebecRDf = "QuebecRDf", //Lien
    quebecRG80 = "QuebecRG80", //Secured Party Transfer
    quebecRE = "QuebecRE", //Partial Discharge
    saskatchewanNotations = "SaskatchewanNotations",
    saskatchewanDebtor = "SaskatchewanDebtor",
    saskatchewanSecuredParty = "SaskatchewanSecuredParty",
    saskatchewanGeneralProperty = "SaskatchewanGeneralProperty",
    saskatchewanSerialProperty = "SaskatchewanSerialProperty",
    saskatchewanRIN = "SaskatchewanRIN"
}

export enum eFinanceType {
    Loan = 1,
    Lease = 2
}

export enum eRouteOnePartyType {
    Lender = 1,
    Dealer = 2
}

export enum eValidationLogType {
    Error = "Error",
    Warning = "Warning",
    Notice = "Notice"
}

export enum eBusinessSearchCriteriaType {
    None = 0,
    Name = 1,
    Number = 2
}

export enum eBusinessSearchCriteriaTypeString {
    None = "None",
    Name = "Name",
    Number = "Number"
}

export enum eRequestedReportType {
    Orders = "Orders",
    SLA = "SLA",
    UnpaidInvoices = "UnpaidInvoices",
    CustomerPricing = "CustomerPricing",
    PricingTiers = "PricingTiers"
}

export enum eSerialNumberActivityLevel {
    Green = "Green",
    Yellow = "Yellow",
    Red = "Red"
}
<div class="padding" *ngIf="hasExistingParties()">
    <div class="flex-container">
        <mat-form-field class="flex-item-6" appearance="outline">
            <mat-select placeholder="Reusable Party" [(ngModel)]="this.selectedExistingPartyID">
                <mat-option *ngFor="let party of existingParties; let i = index" [value]="party.id!"
                    [attr.data-testid]="'existing-party-option-' + i">
                    {{ party.fullName! }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button
            (click)="saveExistingParty()"
            [disabled]="selectedExistingPartyID == ''"
            color="primary"
            class="margin-top-15 margin-left-25 flex-item"
            aria-label="Save Reusable Party button"
            data-testid="add-reusable-party-button">
            Add Reusable Party
        </button>
    </div>
</div>
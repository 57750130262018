import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { Idle } from '@ng-idle/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { ToastContainerDirective, ToastrModule } from 'ngx-toastr';
import { DashboardComponent } from './app/components/dashboard/dashboard.component';
import { LoggedOutComponent } from './app/components/logged-out/logged-out.component';
import { SideMenuComponent } from './app/components/side-menu/side-menu.component';
import { TopMenubarComponent } from './app/components/top-menubar/top-menubar.component';
import { FailedOrdersComponent } from './app/components/failed-orders/failed-orders.component';
import { ManualOrdersComponent } from './app/components/manual-orders/manual-orders.component';
import { DatabaseOverviewComponent } from './app/components/database-overview/database-overview.component';
import { AutomationMonitorsComponent } from './app/components/automation-monitors/automation-monitors.component';
import { QueueMonitorsComponent } from './app/components/queue-monitors/queue-monitors.component';
import { ScheduledMonitorsComponent } from './app/components/scheduled-monitors/scheduled-monitors.component';
import { environment } from '../environments/environment';
import { UiCommonModule } from 'reg-hub-common';
import { DatePipe } from '@angular/common';
import { CallbackComponent } from './app/components/callback/callback.component';
import { httpInterceptorProviders } from './shared/http-interceptors';
import { SystemEventNotificationComponent } from './app/components/system-event-notification/system-event-notification.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { RegHubDateAdapter } from 'reg-hub-common';
import { EnvelopesComponent } from './app/components/envelopes/envelopes.component';
import { EnvelopeOrdersPopupComponent } from './app/components/envelope-orders-popup/envelope-orders-popup.component';
import { REG_HUB_DATE_FORMATS } from 'reg-hub-common';
import { BatchSummariesComponent } from './app/components/batch-summaries/batch-summaries.component';
import { ClientListComponent } from './app/components/client-list/client-list.component';
import { ClientFormComponent } from './app/components/client-form/client-form.component';
import { ClientFilterComponent } from './app/components/client-filter/client-filter.component';
import { OrderAuditFilterComponent } from './app/components/order-audit-filter/order-audit-filter.component';
import { OrderAuditListComponent } from './app/components/order-audit-list/order-audit-list.component';
import { RouteReuseStrategy } from '@angular/router';
import { OrdersReuseStrategy } from './orders-reuse-strategy';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoggedOutComponent,
    TopMenubarComponent,
    SideMenuComponent,
    FailedOrdersComponent,
    ManualOrdersComponent,
    DatabaseOverviewComponent,
    AutomationMonitorsComponent,
    QueueMonitorsComponent,
    ScheduledMonitorsComponent,
    CallbackComponent,
    SystemEventNotificationComponent,
    EnvelopesComponent,
    EnvelopeOrdersPopupComponent,
    BatchSummariesComponent,
    ClientListComponent,
    ClientFormComponent,
    ClientFilterComponent,
    OrderAuditFilterComponent,
    OrderAuditListComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule.forRoot({
      extendedTimeOut: 0,
      timeOut: 0,
      maxOpened: 4,
      positionClass: 'inline',
      easeTime: 0,
      preventDuplicates: true
    }),
    ToastContainerDirective,
    UiCommonModule.forRoot({ rootUrl: environment.urlAddress, batchRootURL: environment.batchUrlAddress, consortiumRootURL: environment.consortiumUrlAddress }),
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    httpInterceptorProviders,
    DatePipe,
    { provide: DateAdapter, useClass: RegHubDateAdapter},
    Idle,
    { provide: MAT_DATE_FORMATS, useValue: REG_HUB_DATE_FORMATS },
    { provide: RouteReuseStrategy, useClass: OrdersReuseStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
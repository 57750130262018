import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';
import { InternalSearchCriteria, NavItem, OrderAuditsRepositoryService, OrderRepositoryService } from 'reg-hub-common';
import { ConfigurationState } from 'reg-hub-common';
import { OrdersAuditsHubService } from '../../../services/hubs/audits-hub/order-audits-hub.service';
import { OrderMessagesHubService } from '../../../services/hubs/order-messages-hub/order-messages-hub.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit, AfterViewInit {
  @Input() isSidenavOpen: boolean = true;

  @ViewChild(ToastContainerDirective, { static: true }) toastContainer!: ToastContainerDirective

  selectedItem = '';
  isSearching = false;
  pendingAuditCount: number = 0;
  unreadMessageCount: number = 0;

  // This will come from a DB call to which nav items a user is allowed to see
  navItems: NavItem[] = [
    { label: 'Dashboard', icon: 'home', link: '/dashboard'},
    { label: 'Orders', icon: 'search', link: '/orders' },
    { label: 'Batches', icon: 'library_books', link: '/batches' }, 
    { label: 'Corporations', icon: 'apartment', link: '/corporations' },
    { label: 'Pricing', icon: 'attach_money', link: '/pricing' },
    { label: 'Invoicing', icon: 'payments', link: '/invoices' },
    { label: 'Credentials', icon: 'vpn_key', link: '/registry-credentials' },
    { label: 'Reports', icon: 'assessment', link: '/reports' },
    { label: 'Audit Queue', icon: 'zoom_in', link: '/order-audits'},
    { label: 'Messages', icon: 'message', link: '/messages' },
    { label: 'Logs', icon: 'book', link: '/logs' },
    { label: 'Integrations', icon: 'handshake', link: '/integrations' },
    { label: 'API Clients', icon: 'webhook', link: '/clients' },
    { label: 'System', icon: 'settings', link: '/system' },
    { label: 'Consortium', icon: 'experiment', link: '/consortium' }
  ];

  constructor(
    private messageService: ToastrService,
    private router: Router,
    //private configurationState: ConfigurationState,
    private ordersAuditService: OrderAuditsRepositoryService,
    private ordersHubService: OrdersAuditsHubService,
    private ordersRepo: OrderRepositoryService,
    private orderMessagesHubService: OrderMessagesHubService) { }

  ngOnInit(): void {
    this.ordersHubService.connect();
    this.orderMessagesHubService.connect();
    this.messageService.overlayContainer = this.toastContainer;

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => this.messageService.clear());
  }

  ngAfterViewInit() {
    this.ordersAuditService.getPendingAuditCount().subscribe(count => {
      this.ordersHubService.setInitialAuditPendingCount(count);
      this.monitorPendingAuditCount();
    });

    this.ordersRepo.getOrdersWithUnreadAdminMessages().subscribe(result => {
      this.orderMessagesHubService.setInitialUnreadMessageCount(result.total);
      this.monitorUnreadMessageCount();
    });
  }

  ngOnDestroy() {
    this.ordersHubService.leavePendingAuditsGroup();
  }

  handleClick(selectedItem: NavItem) {
    this.selectedItem = selectedItem.label;
  }

  onSearch(search: InternalSearchCriteria) {
    this.isSearching = true;

    switch (search.typeOfSearch) {
        case 'referenceNumber':
            this.searchByReferenceNumber(search.criteria);
            break;
        case 'firstName':
            this.searchByFirstName(search.criteria);
            break;
        case 'lastName':
            this.searchByLastName(search.criteria);
            break;
        case 'businessName':
            this.searchByBusinessName(search.criteria);
            break;
        case 'serialNumber':
            this.searchBySerialNumber(search.criteria);
            break;
        case 'dateOfBirth':
            this.searchByDateOfBirth(search.criteria);
            break;
        case 'orderId':
            this.searchByOrderId(search.criteria);
            break;
        case 'registrationOrFileNumber':
            this.searchByRegistrationOrFileNumber(search.criteria);
            break;
        case 'externalOrderNumber':
            this.searchByExternalOrderNumber(search.criteria);
            break;
        default:
            console.error('Invalid search option');
            break;
    }
  }

  searchByReferenceNumber(referenceNumber: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      referenceNumber: referenceNumber,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByFirstName(firstName: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      firstName: firstName,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByLastName(lastName: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      lastName: lastName,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByBusinessName(businessName: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      businessName: businessName,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchBySerialNumber(serialNumber: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      serialNumber: serialNumber,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByDateOfBirth(dateOfBirth: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      dateOfBirth: dateOfBirth,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByRegistrationOrFileNumber(registrationNumber: string) {
    const lienOrderTypes = [
      'Lien',
      'Renewal',
      'Amendment',
      'Discharge',
      'BasicRenewal',
      'BasicDischarge'
  ];

    let params = {
      autoExpand: false,
      autoRedirect: true,
      orderTypes: lienOrderTypes,
      registrationOrFileNumber: registrationNumber
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByExternalOrderNumber(externalOrderNumber: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      externalOrderNumber: externalOrderNumber,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByOrderId(orderId: string) {
      this.router.navigate(['orders', orderId]);
  }

  monitorPendingAuditCount() {
    this.ordersHubService.joinPendingAuditsGroup();

    this.ordersHubService.pendingAuditCount$.subscribe((count) => {
      //Observable
      this.pendingAuditCount = count;
    });
  }

  monitorUnreadMessageCount() {
    this.orderMessagesHubService.unreadMessageCount$.subscribe((count) => {
      //Observable
      this.unreadMessageCount = count;
    });
  }
}

import { Inject, Injectable } from '@angular/core';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { AutomationPageLog } from '../../interfaces/automation-page-log';

@Injectable({
  providedIn: 'root'
})
export class AutomationPageLogsRepositoryService extends PaginatedRepository<AutomationPageLog> {
  protected override _baseLink: string;

  constructor(
    http: HttpClient,
    messageService: ToastrService,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
    super(
      http,
      messageService);

    this._baseLink = `${this.config.rootUrl}/Logs/AutomationPageLogs`;
  }

  public viewHtml(log: AutomationPageLog) {
    if (log.document.documentTypeID === 'HTML') {
      const headers = new HttpHeaders()
        .append('Accept', 'text/html');

      this.http.get(`${this._baseLink}/${log.id}`, { headers: headers, responseType: 'text' }).subscribe(html => {
        const blob = new Blob([html], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        setTimeout(() => URL.revokeObjectURL(url), 1000);
      });
    } else if (log.document.documentTypeID === 'PDF') {
      const headers = new HttpHeaders()
        .append('Accept', 'application/pdf');

      this.http.get(`${this._baseLink}/${log.id}`, { headers: headers, responseType: 'blob' }).subscribe(pdfBlob => {
        const blob = new Blob([pdfBlob], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        setTimeout(() => URL.revokeObjectURL(url), 1000);
      });
    }
  }
}

<div class="flex-container">
    <div class="flex-item">
        <h3>Report Criteria</h3>
        <div class="flex-container" *ngIf="order.businessReportCriteria!.businessName">
            <span class="flex-item">Business Name:</span>
            <span class="flex-item-2">{{ order.businessReportCriteria!.businessName }}</span>
        </div>
        <div class="flex-container">
            <span class="flex-item">Number:</span>
            <span class="flex-item-2">{{ order.businessReportCriteria!.number }}</span>
        </div>
    </div>
</div>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Asset } from '../../interfaces/assets/asset';
import { Party } from '../../interfaces/parties/party';
import { SearchParameters } from '../../interfaces/search-parameters/search-parameters';
import { Subject, takeUntil } from 'rxjs';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { ePartyType } from '../../enums';

@Component({
    selector: 'lib-order-search-criteria',
    templateUrl: './order-search-criteria.component.html',
    styleUrls: ['./order-search-criteria.component.css']
})
export class OrderSearchCriteriaComponent implements OnInit, OnDestroy {
    order!: Order
    searchAsset: Asset | null = null;
    searchDebtor: Party | null = null;
    searchParameters: SearchParameters | null = null;
    @Input() shouldDisplayExactSimilar: boolean = true;
    @Input() shouldDisplayAssetType: boolean = false;
    @Input() shouldDisplaySearchFromDate: boolean = true;

    private unsubscribe$ = new Subject<void>();

    isDebtorSearch: boolean = false;
    isAssetSearch: boolean = false;
    isIndividual: boolean = false;

    constructor(private orderManager: OrderManagerService) { }

    ngOnInit(): void {
        this.order = this.orderManager.currentOrder.value;

        this.searchParameters = this.order.searchParameters!;
        this.searchDebtor = ((this.order.parties?.length ?? 0) > 0) ? this.order.parties![0] : null;
        this.searchAsset = ((this.order.assets?.length ?? 0) > 0) ? this.order.assets![0] : null;

        this.isDebtorSearch = this.order?.orderType?.id == "DebtorSearch" || this.order?.orderType?.id == "CertifiedDebtorSearch";
        this.isAssetSearch = this.order?.orderType?.id == "AssetSearch";
        this.isIndividual = this.searchDebtor?.partyTypeID === ePartyType.individualDebtor;
    }

    isSearchOrder(): boolean {
        return this.isDebtorSearch || this.isAssetSearch;
    }

    ngOnDestroy(): void {
        // Signal all subscriptions to complete
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getAssetType() {
        return this.searchAsset?.assetTypeID.toString().replace(/([A-Z])/g, ' $1').trim();
    }

    shouldShowSearchParameters(): boolean {
        return (this.shouldDisplayExactSimilar || this.shouldDisplaySearchFromDate);
    }
}

import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Order } from '../../interfaces/orders/order';
import { OrderMessage } from '../../interfaces/order-messages/order-messasge';
import { OrderMessagesRepositoryService } from '../../services/order-messages/order-messages-repository.service';
import { OrderMessageForUpdate } from '../../interfaces/order-messages/order-message-for-update';
import { OrderMessageForCreation } from '../../interfaces/order-messages/order-message-for-creation';

@Component({
  selector: 'lib-order-messages',
  templateUrl: './order-messages.component.html',
  styleUrls: ['./order-messages.component.css']
})
export class OrderMessagesComponent {
  isSaving: boolean = false;
  order: Order;
  isCustomer: boolean;
  orderMessages: OrderMessage[] = [];
  // validState: boolean = false;
  newMessage: OrderMessageForCreation = {
    message: "",
    isCustomerVisible: true
  }

  displayColumns: string[] = [
    'message',
    'added',
    'customerRead'
  ];

  customerDisplayedColumns = [
    ...this.displayColumns,
  ]

  adminisplayedColumns = [
    ...this.displayColumns,
    'adminRead',
    'customerVisible',
    'delete'
  ]

  constructor(protected orderMessageService: OrderMessagesRepositoryService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    this.order = dialogData.order;
    this.isCustomer = dialogData.customer;

    // this.validState = !this.isCustomer;
      
    if (this.isCustomer) {
      //Only Customer Visible Items
      this.orderMessages = this.order.orderMessages?.filter((u) => u.isCustomerVisible) || [];
    }
    else {
      this.orderMessages = this.order.orderMessages || [];
    }
  }

  updateOrderMessage(orderMessage: OrderMessage) {
    this.orderMessageService.updateOrderMessage(orderMessage.orderID, orderMessage.id, orderMessage as OrderMessageForUpdate)
      .subscribe(() => {
        this.resetOrderMessages();
      });
  }

  deleteMessage(orderMessage: OrderMessage) {
    this.orderMessageService.deleteOrderMessage(orderMessage.orderID, orderMessage.id).subscribe({
      next: () => {
        this.orderMessages = this.orderMessages.filter((u) => u.id != orderMessage.id);
        this.resetOrderMessages();
      },
      error: () => {
        
      }
    });
  }

  saveMessage() {
    if (!this.newMessage.message.trim()) {
      return;
    }

    this.isSaving = true;

    this.orderMessageService.createOrderMessage(this.order.id, this.newMessage)
      .subscribe({
        next: result => {
          this.newMessage.message = "";
          this.orderMessages.push(result);
          this.resetOrderMessages();
          this.isSaving = false;
        },
        error: () => {
          this.isSaving = false;
        }
      });
  }

  private resetOrderMessages() {
    this.order.orderMessages = this.orderMessages;
  }
}
<div class="circular-progress-container">
    <ng-container *ngIf="!isInfiniteTerm; else infiniteTermTemplate">
        <!-- Existing circular progress indicator -->
        <div class="custom-progress-circle">
            <svg viewBox="0 0 36 36">
                <path
                    class="background-circle"
                    d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831">
                </path>
                <path
                    class="progress-circle"
                    [attr.stroke-dasharray]="100"
                    [attr.stroke-dashoffset]="100 - progress"
                    d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831">
                </path>
            </svg>
            <div class="progress-text">
                <p data-testid="lein-lifetime-remaining-text">{{ timeRemaining }}</p>
            </div>
        </div>
    </ng-container>
    <ng-template #infiniteTermTemplate>
        <div class="infinite-symbol">
            <div class="permanent-text">
                <p>Infinite Term</p>
            </div>
            <svg viewBox="0 0 100 55" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                <path
                    d="M10,27.5
                    C10,7 30,7 50,27.5
                    C70,48 90,48 90,27.5
                    C90,7 70,7 50,27.5
                    C30,48 10,48 10,27.5
                    Z"
                stroke="var(--color-primary)"
                stroke-width="4"
                fill="none" />
            </svg>
        </div>
      </ng-template>
    <!-- Date labels -->
    <div class="date-labels">
        <span data-testid="lein-lifetime-registration-text">{{ registrationDate | date: 'MMM d, y' }}</span>
        <mat-icon *ngIf="!isInfiniteTerm" class="arrow-icon">arrow_forward</mat-icon>
        <span *ngIf="!isInfiniteTerm" data-testid="lein-lifetime-expiry-text">{{ expiryDate | date: 'MMM d, y' }}</span>
    </div>
</div>
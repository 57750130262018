import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, take } from 'rxjs';
import { CorporationRepositoryService, EnumeratedTypesRepositoryService, FiltersComponent, OrderAudit, OrderAuditsRepositoryService, SelectOption } from 'reg-hub-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { EnvironmentUrlService } from '../../../services/environment-url/environment-url.service';

@Component({
    selector: 'app-order-audit-filter',
    templateUrl: './order-audit-filter.component.html',
    styleUrls: ['./order-audit-filter.component.css']
})
export class OrderAuditFilterComponent extends FiltersComponent<OrderAudit> implements OnInit {
    protected _orderTypeOptions: SelectOption[] = [];
    protected _corporations: SelectOption[] = [];

    constructor(
        repo: OrderAuditsRepositoryService,
        formBuilder: FormBuilder,
        datePipe: DatePipe,
        private environment: EnvironmentUrlService,
        private enumerationRepo: EnumeratedTypesRepositoryService,
        private corporationsRepo: CorporationRepositoryService
    ) {
        super(repo, formBuilder, datePipe);
    }

    ngOnInit(): void {
        let requests = [
            this.enumerationRepo.getOrderTypesAsSelectOptions(this.environment.urlAddress),
            this.corporationsRepo.getCorporationsAsSelectOptions(),
        ]

        forkJoin(requests).subscribe(values => {
            this._orderTypeOptions = values[0],
                this._corporations = values[1]
        })

        this._filterForm = this.formBuilder.group({
            orderTypes: [null],
            orderCountries: [null],
            orderJurisdictions: [null],
            orderCorporationID: [null],
            auditDateFrom: [null],
            auditDateTo: [null],
            auditPassed: [null],
            isAudited: [false]
        });

        this._defaultParams = this.buildParamsFromForm(this._filterForm);

        this.repo.getFirstPage(this._defaultParams);
    }

    protected jurisdictionFormChanges(event: FormGroup) {
        this._filterForm.setControl('orderCountries', event);
        this._filterForm.setControl('orderJurisdictions', event);
    }
}

<div class="min-height-150">
    <span *ngIf="noResults(); else hasResults">
        <lib-placeholder iconName="cloud_off" iconClass="icon-info" message="No Results. Consider refining your search criteria."
            [condition]="noResults()"></lib-placeholder>
    </span>
    <ng-template #hasResults>
        <div *ngIf="resultsOverflow()" class="notice" data-testid="results-overflow-text">
            <mat-icon class="icon">info</mat-icon>This search may have exceeded the registry maximum results limit. Consider refining your search criteria.
        </div>
        
        <div *ngIf="order.orderStatusTypeID != 'Complete'; else results"
            class="loading-spinner-overlay spinner-animation">
            <mat-spinner></mat-spinner>
        </div>

        <ng-template #results>
            <div [@loadingToLoaded]="order.orderStatusTypeID" *ngIf="this.repo.resource$ | async as data">
                <div class="results-animation">
                    <lib-business-search-results-filter
                        [jurisdictions]="resultJurisdictions" [statuses]="resultStatuses" [types]="resultTypes"
                        [descriptions]="resultDescriptions" [jurisdictionStatuses]="resultJurisdictionStatuses"
                        [hasData]="((this.repo.resourceCount$ | async) ?? 0) > 0">
                    </lib-business-search-results-filter>
                    <div class="spacer-small"></div>

                    <div class="table-container" *ngIf="data && order.orderStatusTypeID == 'Complete'">

                        <div *ngIf="data.length >= 50">
                            <mat-paginator [pageSize]="this.repo.pageSize$ | async"
                                [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                                [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true" (page)="page($event)">
                            </mat-paginator>
                            <div class="spacer-small"></div>
                        </div>

                        <table mat-table matSort [dataSource]="data" (matSortChange)="sort($event)" multiTemplateDataRows
                            *ngIf="data.length > 0">
                        
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>Name</th>
                                <td mat-cell *matCellDef="let result">{{ result.name }}</td>
                            </ng-container>

                            <ng-container matColumnDef="number">
                                <th mat-header-cell *matHeaderCellDef>Number</th>
                                <td mat-cell *matCellDef="let result">{{ result.number }}</td>
                            </ng-container>

                            <ng-container matColumnDef="jurisdiction">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Jurisdiction</th>
                                <td mat-cell *matCellDef="let result">{{ result.jurisdiction }}</td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                <td mat-cell *matCellDef="let result">{{ result.status }}</td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                                <td mat-cell *matCellDef="let result" class="action-cell">
                                    <ng-container *ngIf="shouldShowOrderReportButton(result); else pendingReport">
                                        <div matTooltip="The Report for this result is unavailable"
                                            matTooltipPosition="right"
                                            [matTooltipDisabled]="isReportAvailable(result)">
                                            <button mat-button
                                                color="primary"
                                                aria-label="Order Report"
                                                (click)="orderReport(result); $event.stopPropagation()"
                                                class="no-shadow"
                                                [disabled]="!result.isReportAvailable">
                                                <mat-icon>description</mat-icon>
                                                Order Report
                                            </button>
                                        </div>
                                    </ng-container>
                                
                                    <ng-template #pendingReport>
                                        <ng-container *ngIf="shouldShowPendingReportButton(result); else updateReport">
                                        <button mat-button
                                            color="primary"
                                            aria-label="Report Pending"
                                            (click)="navigateToOrder(result); $event.stopPropagation()"
                                            class="no-shadow">
                                            <mat-icon>pending</mat-icon>
                                            Report Pending
                                        </button>
                                        </ng-container>
                                    </ng-template>
                                
                                    <ng-template #updateReport>
                                        <ng-container *ngIf="shouldShowViewReportButton(result)">
                                        <button mat-button
                                            color="primary"
                                            aria-label="View Report"
                                            (click)="navigateToOrder(result); $event.stopPropagation()"
                                            class="no-shadow">
                                            <mat-icon>article</mat-icon>
                                            View Report
                                        </button>
                                        </ng-container>
                                    </ng-template>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="indicator">
                                <th mat-header-cell *matHeaderCellDef id="indicator"></th>
                                <td mat-cell *matCellDef="let result">
                                    <lib-disclosure-indicator
                                        [isExpanded]="result === _expandedResult"></lib-disclosure-indicator>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let result" [attr.colspan]="columnsToDisplayWithView.length">
                                    <div class="expanded-entity-detail"
                                        [@detailExpand]="result === _expandedResult ? 'expanded' : 'collapsed'">
                                        <div class="expanded-entity-information">
                                            <lib-business-search-result-preview
                                                [result]="result"></lib-business-search-result-preview>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithView"></tr>
                            <tr mat-row *matRowDef="let result; columns: columnsToDisplayWithView;"
                                class="expandable-table-row" [class.example-expanded-row]="_expandedResult === result"
                                (click)="_expandedResult = _expandedResult === result ? null : result">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row">
                            </tr>

                        </table>

                        <div class="padding center padding-top-bottom-50"
                            *ngIf="((this.repo.loading$ | async) ?? false) && data.length === 0">
                            &nbsp;
                        </div>

                        <div class="spacer-small"></div>
                        <mat-paginator *ngIf="data.length > 0" [pageSize]="this.repo.pageSize$ | async"
                            [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                            [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true" (page)="page($event)">
                        </mat-paginator>
                    </div>
                    <lib-list-issue [repo]="this.repo" [data]="data"></lib-list-issue>

                </div>
            </div>
        </ng-template>
    </ng-template>
</div>
<table [@slideDown]="originalOrder.id ? 'visible' : 'hidden'" *ngIf="orderChanges$.value.length > 0; else noChanges" mat-table [dataSource]="orderChanges$"
  data-testid="order-changes-table">
  <ng-container matColumnDef="operation">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let change"><mat-icon [matTooltip]="getIconTooltip(change)" matTooltipClass="reghub-tooltip"
        data-testid="order-changes-icon">
        {{ getIcon(change) }}</mat-icon>
    <td>
  </ng-container>

  <ng-container matColumnDef="path">
    <th mat-header-cell *matHeaderCellDef> Item Affected </th>
    <td mat-cell *matCellDef="let change" data-testid="order-changes-item-affected">{{ convertPath(change) }}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef> Change Description </th>
    <td mat-cell *matCellDef="let change" class="mat-column-description" [innerHtml]="formatDescription(change)" data-testid="order-changes-change-description"></td>
  </ng-container>

  <ng-container matColumnDef="forms">
    <td mat-footer-cell *matFooterCellDef colspan="3" class="text-faded" data-testid="order-changes-forms">
      *Registry forms that will be used to perform the amendment(s): {{ formText }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="[ 'operation', 'path', 'description' ]"></tr>
  <tr mat-row *matRowDef="let row; columns: [ 'operation', 'path', 'description' ];"></tr>
  <ng-container *ngIf="formText && uiConfiguration.amendmentConfiguration?.showFormsSection">
    <tr mat-footer-row *matFooterRowDef="[ 'forms' ]"></tr>
  </ng-container>
</table>
<ng-template #noChanges>
  <div class="margin-bottom-25 margin-top-25 text-faded flex-container-center" data-testid="order-changes-no-changes">
    No changes found.
  </div>
</ng-template>
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

export class OrdersReuseStrategy implements RouteReuseStrategy {
    shouldDetach(_route: ActivatedRouteSnapshot): boolean { return false; }
    store(_route: ActivatedRouteSnapshot, _handle: DetachedRouteHandle): void {}
    shouldAttach(_route: ActivatedRouteSnapshot): boolean { return false; }
    retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null { return null; }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // never reuse /orders route, always reload because parameters may have changed
        if (future.routeConfig?.path === 'orders') {
            return false;
        }

        // do reload everything else
        return future.routeConfig === curr.routeConfig;
    }
}
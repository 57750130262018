<div class="padding">
    <h1 class="left-box">Audit Queue</h1>

    <div *ngIf="this.repo.resource$ | async as data">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="bold">
                    Filters
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Optional Filter -->
            <app-order-audit-filter></app-order-audit-filter>
        </mat-expansion-panel>

        <div class="spacer-small"></div>

        <!-- Clients Table -->
        <div class="table-container">

            <!-- Conditional paginator at the top (show only when number of orders displayed is >= 50) -->
            <div *ngIf="data.length >= 50">
                <mat-paginator [pageSize]="this.repo.pageSize$ | async"
                    [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                    [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true"
                    (page)="page($event)"></mat-paginator>
                <div class="spacer-small"></div>
            </div>
            <!-- End of conditional paginator at the top -->

            <table mat-table matSort [dataSource]="data" multiTemplateDataRows
                (matSortChange)="sort($event)" *ngIf="data.length > 0">

                <!-- Corporation Column -->
                <ng-container matColumnDef="corporation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Corporation </th>
                    <td mat-cell *matCellDef="let audit"> {{ audit.orderCorporationID }} </td>
                </ng-container>

                <!-- Order Type Column -->
                <ng-container matColumnDef="orderTypeID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Type </th>
                    <td mat-cell *matCellDef="let audit"> {{ audit.orderTypeID }} </td>
                </ng-container>

                <!-- Country Column -->
                <ng-container matColumnDef="orderCountry">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
                    <td mat-cell *matCellDef="let audit"> {{ audit.orderCountry }} </td>
                </ng-container>

                <!-- Jurisdiction Column -->
                <ng-container matColumnDef="orderJurisdiction">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Jurisdiction </th>
                    <td mat-cell *matCellDef="let audit"> {{ audit.orderJurisdiction }} </td>
                </ng-container>

                <!-- Audit Passed Column -->
                <ng-container matColumnDef="auditPassed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Passed </th>
                    <td mat-cell *matCellDef="let audit"> {{ audit.auditPassed }} </td>
                </ng-container>

                <!-- Audit Date Column -->
                <ng-container matColumnDef="auditeDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Audit Date </th>
                    <td mat-cell *matCellDef="let audit"> {{ audit.auditDate | utcToLocalDate }} </td>
                </ng-container>

                <!-- Audit By Column -->
                <ng-container matColumnDef="auditedByName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Audited By </th>
                    <td mat-cell *matCellDef="let audit"> {{ audit.auditedByName }} </td>
                </ng-container>

                <ng-container matColumnDef="view">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let order">
                        <button mat-icon-button color="accent" (click)="viewOrder(order, false)" aria-label="View Order">
                            <mat-icon>article</mat-icon>
                        </button>
                        <button mat-icon-button color="accent" (click)="viewOrder(order, true)" aria-label="View Order">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Header and Row Declarations -->
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
            </table>

            <mat-paginator *ngIf="data.length > 0" [pageSize]="this.repo.pageSize$ | async"
                [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true"
                (page)="page($event)"></mat-paginator>
            <!-- End of paginator for the bottom -->
        </div>

        <div *ngIf="this.repo.loading$ | async" class="loading-spinner-overlay">
            <mat-spinner></mat-spinner>
        </div>
        <!-- End of spinner -->

        <lib-placeholder [iconName]="'cancel'" [iconClass]="'icon-error'" message="Error loading Order Audits"
            [condition]="(this.repo.error$ | async) ?? false"></lib-placeholder>
    </div>
</div>
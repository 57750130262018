<div class="flex-container" *ngIf="uiConfiguration.showFileNumber">
  <span class="flex-item">File Number:</span>
  <span class="flex-item-2" data-testid="discharge-details-file-number-text">{{ order.lien?.fileNumber }}</span>
</div>
<div class="flex-container">
  <span class="flex-item">Discharge Number:</span>
  <span class="flex-item-2" data-testid="discharge-details-number-text">{{ order.discharge?.dischargeNumber }}</span>
</div>
<div class="flex-container">
  <span class="flex-item">Discharge Date:</span>
  <span class="flex-item-2" data-testid="discharge-details-date-text">{{ order.discharge?.dischargeDate | localizedDate: "yyyy-MM-dd" }}</span>
</div>
<div class="flex-container" *ngIf="uiConfiguration.showDischargeType && order.discharge?.dischargeType">
  <span class="flex-item">Discharge Type:</span>
  <span class="flex-item-2" data-testid="discharge-details-type-text">{{ order.discharge?.dischargeType }}</span>
</div>
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'utcToLocalDate'
})
export class UTCToLocalDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: Date | null | undefined, format: string = 'yyyy-MM-dd HH:mm'): string | null {
    // Get the client's timezone
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!value) {
      return "";
    }

    //Convert to an actual Date type if necessary 
    let date = new Date(value!.toString());
    let utdDate = Date.UTC(date.getFullYear(),
      date.getMonth(), 
      date.getDate(), 
      date.getHours(), 
      date.getMinutes(), 
      0, 
      0);


    // Format the date using the client timezone
    return this.datePipe.transform(utdDate, format, clientTimezone);
  }
}
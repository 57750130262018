<div class="flex-container-space-between">
    <div class="button-container" *ngIf="!hideHistory && uiConfiguration?.showCollateralHistory">
        <a mat-flat-button color="primary" (click)="openGeneralCollateralHistory()" 
            data-testid="general-collateral-history-button">General Collateral History</a>
    </div>
</div>
<div *ngIf="uiConfiguration?.showSecurityInterest ?? false" class="flex-container margin-bottom-15">
    <mat-checkbox class="flex-item" name="securityInterest" disabled="true" [checked]="order.lien?.securityInterest ?? false"
        data-testid="general-collateral-security-interest-checkbox">
        The security interest is taken in all of the debtor's present and after-acquired personal property.</mat-checkbox>
</div>
<div *ngIf="order.lien?.generalCollateral?.collateralValue">
    <div class="light-bold" *ngIf="uiConfiguration?.showCollateralHistory ?? false">
        Replace all collateral with:
    </div>
    <div class="preserve-line-breaks" data-testid="general-collateral-replace-with-text">
        {{ order.lien?.generalCollateral?.collateralValue }}
    </div>
</div>
<div *ngIf="order.lien?.generalCollateral?.collateralToAdd">
    <div class="light-bold">
        Add collateral:
    </div>
    <div class="preserve-line-breaks" data-testid="general-collateral-add-text">
        {{ order.lien?.generalCollateral?.collateralToAdd }}
    </div>
</div>
<div *ngIf="order.lien?.generalCollateral?.collateralToRemove">
    <div class="light-bold">
        Remove collateral:
    </div>
    <div class="preserve-line-breaks" data-testid="general-collateral-remove-text">
        {{ order.lien?.generalCollateral?.collateralToRemove }}
    </div>
</div>
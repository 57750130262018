import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OnServerDataMessageArgs, WebPubSubClient } from '@azure/web-pubsub-client';
import { Observable, ReplaySubject, BehaviorSubject, from } from 'rxjs';
import { Constants } from 'projects/reg-hub-admin/src/constants';
import { EnvironmentUrlService } from '../../environment-url/environment-url.service';
import { OrderMessage } from 'reg-hub-common';

@Injectable({
  providedIn: 'root'
})
export class OrderMessagesHubService implements OnDestroy {
  private client: WebPubSubClient | undefined;
  private messagesSubject = new ReplaySubject<any>(1);
  public messages$: Observable<any> = this.messagesSubject.asObservable();

  private messagesCount = 'MessagesCount';

  private isConnected = false;

  // BehaviorSubjects to store orders
  private unreadMessageCountSource = new BehaviorSubject<number>(0);
  unreadMessageCount$ = this.unreadMessageCountSource.asObservable();

  constructor(private http: HttpClient,
    private environmentUrl: EnvironmentUrlService
  ) {}

  connect(): void {
    if (!this.isConnected) {
      this.http.get<any>(`${this.environmentUrl.urlAddress}${Constants.orderMessagesHubNegotiateUrl}`)
        .subscribe({
          next: response => this.completeConnection(response.url),
          error: err => console.error("Error negotiating URL:", err)
        });
    }
  }

  private completeConnection(url: string): void {
    this.client = new WebPubSubClient({
      getClientAccessUrl: async () => url
    });

    // Set up event handlers
    this.client.on("connected", (e) => {
      console.log("Connected to Orders hub:", e.connectionId);
      this.isConnected = true;
    });

    // handle messages to a group when received
    this.client.on("server-message", (messageEvent) => {
      this.handleMessageReceived(messageEvent);
    });

    // handle disconnection from the hub
    this.client.on("disconnected", () => {
      console.warn("Disconnected from Web PubSub");
      this.isConnected = false;
    });

    // Start the connection
    from(this.client.start()).subscribe({
      next: () => {
        console.log('Client started');
      },
      error: err => console.error('Error starting client', err)
    });
  }

  handleMessageReceived(messageEvent: OnServerDataMessageArgs) {
    if (messageEvent.message.dataType === "json") {
      const rawMessage = messageEvent.message.data;
      const message: OrderMessage = this.convertKeysToLowercase<OrderMessage>(rawMessage);
      this.updateUnreadMessageCount(message);
      this.messagesSubject.next(message);
    }
  }

  setInitialUnreadMessageCount(count: number) {
    this.unreadMessageCountSource.next(count);
  }

  private updateUnreadMessageCount(message: OrderMessage) {
    var currentCount = this.unreadMessageCountSource.value;
    if (message.isReadByAdmin)
    {
      currentCount -= 1;
    } else {
      currentCount += 1;
    }
    this.unreadMessageCountSource.next(currentCount);
  }

  ngOnDestroy() {
    // Stop the connection if the service is destroyed (e.g., in certain testing scenarios)
    if (this.client) {
      this.client.stop();
    }
  }

  private convertKeysToLowercase<T>(input: any): T {
    const transformedObject: any = {};
    for (const key of Object.keys(input)) {
      transformedObject[key.charAt(0).toLowerCase() + key.slice(1)] = input[key];
    }
    return transformedObject as T;
  }
}

export class HubService {
    protected toCamelCase(key: any, value: any) {
        if (value && typeof value === 'object') {
            for (var k in value) {
                // Special case: If the key is exactly 'ID', rename it to 'id'
                if (k === 'ID') {
                    value['id'] = value[k];
                    delete value[k];
                }
                // Otherwise, just lowercase the first letter
                else if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
                    value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
                    delete value[k];
                }
            }
        }
        return value;
    }
}
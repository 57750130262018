import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { SerialNumberActivityLevel } from '../../interfaces/consortium/serial-number-activity-level';

@Injectable({
  providedIn: 'root'
})
export class ConsortiumService {
  constructor(
    private http: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) { }

  getSerialNumberActivityLevel(serialNumber: string, jurisdiction?: string) {
    let params = new HttpParams();

    if (jurisdiction) {
      params = params.append('jurisdiction', jurisdiction);
    }

    return this.http.get<SerialNumberActivityLevel>(`${this.config.consortiumRootURL}/SerialNumbers/${serialNumber}/ActivityLevel`, { params: params });
  }
}

import { Component, Input } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Lien } from '../../interfaces/liens/lien';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { Subject, takeUntil } from 'rxjs';
import { RegistrationResults } from '../../interfaces/registration-results/registration-results';
import { LienDetailsUIConfiguration } from '../../interfaces/ui-configurations/lien-details-ui-configuration';

@Component({
  selector: 'lib-lien-results',
  templateUrl: './lien-results.component.html',
  styleUrls: ['./lien-results.component.css']
})
export class LienResultsComponent {
  @Input() results: RegistrationResults | undefined;
  @Input() uiConfiguration: LienDetailsUIConfiguration | undefined;

  lien!: Lien | null | undefined;

  private onDestroy$ = new Subject<void>();

  constructor(private orderManager: OrderManagerService) { }

  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.lien = order.lien
        this.results = order.lien?.registrationResults?.at(0);
      });
  }
  
  ngOnDestroy(): void {
      this.onDestroy$.next();
      this.onDestroy$.complete();
  }
}
<form [formGroup]="_filterForm" class="padding">
    <div class="margin-top-bottom-25">
        <div class="flex-container">
            <div *ngFor="let field of _textboxFields" class="flex-item">
                <mat-form-field class="form-field">
                    <mat-label>{{ field.label }}</mat-label>
                    <input matInput [formControlName]="field.resourceParameterName" [placeholder]="field?.label ?? ''" />
                </mat-form-field>
            </div>
        </div>

        <div class="flex-container">
            <div *ngFor="let field of _checkboxFields" class="form-field flex-item">
                <mat-checkbox [formControlName]="field.resourceParameterName">
                    {{ field.label }}
                </mat-checkbox>
            </div>
        </div>

        <div class="flex-container">
            <div *ngFor="let field of _dropdownFields" class="flex-item">
                <mat-form-field class="form-field">
                    <mat-select [formControlName]="field.resourceParameterName" [placeholder]="field?.label ?? ''"
                        [multiple]="field.dropdownListOptions?.multi ?? false">
                        <mat-option *ngFor="let option of field.dropdownListOptions?.options" [value]="option.value">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <lib-jurisdiction-selection *ngIf="this._jurisdictionField"></lib-jurisdiction-selection>

        <div class="flex-container">
            <div *ngFor="let field of _datepickerFields" class="flex-item">
                <mat-form-field class="form-field" *ngIf="!field.datepickerOptions?.range ?? true">
                    <input matInput [matDatepicker]="datepicker" [formControlName]="field.resourceParameterName" [placeholder]="field.label ?? ''">
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field *ngIf="field.datepickerOptions?.range ?? false">
                    <mat-label>{{ field.label ?? 'Enter a date range'}}</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input [formControlName]="field.resourceParameterName" matStartDate placeholder="Start date">
                      <input [formControlName]="field.datepickerOptions?.range?.beforeDateResourceParameterName ?? ''" matEndDate placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>                  
            </div>
        </div>
    </div>

    <div>
        <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Clear filters button"
            (click)="onClear()" [disabled]="!_filterForm.dirty">
            Clear Filters
        </button>
        <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Filter button" type="submit"
            (click)="onFilter()">
            Filter
        </button>
    </div>
</form>